/* تنسيق الخلفية للمكون بأكمله */
.info {
  background-color: #01a998; /* لون أخضر */
  padding: 20px;
  border-radius: 10px; /* لجعل الحواف ناعمة */
  max-width: 600px;
  margin: 0 auto; /* لتمركز المكون في المنتصف */
  color: white; /* لون النص أبيض */
  margin: 20px;
}

/* تنسيق العناوين */
h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 15px;
}

/* تنسيق الفقرة */
p {
  font-size: 18px;
  line-height: 1.6;
  text-align: justify; /* لجعل النص متساوي الأطراف */
  margin-bottom: 20px;
}

/* تنسيق الصورة */
img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  border-radius: 10px; /* لجعل الحواف ناعمة */
}
