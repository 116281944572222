.bills_cover{
  width: 90%;
  height: 90%;
  background-color:#01a998;

  margin: auto;
  border: 2bx solid white;
}
.bills_cover .links{
  text-align: center;
  padding: 20px;

}

.bills_cover h2{
  text-align: center;
}
.bills_cover div a{
  position: relative;
  background-color: black;
  border-radius: 4em;
  font-size: 20px;
  color: white;
  padding: 0.3em 0.3em;
  user-select: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  height: 12px;
  margin: 20px;
}

.bills_cover div a:hover {
  transition-duration: 0.1s;
  background-color: #3A3A3A;
}

.bills_cover div a:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 4em;
  left: 0;
  top:0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.5s;
  box-shadow: 0 0 10px 40px white;
}

.bills_cover div a:after {
  box-shadow: 0 0 0 0 white;
  position: absolute;
  border-radius: 4em;
  left: 0;
  top:0;
  opacity: 1;
  transition: 0s;
}

.bills_cover div a:active {
  top: 1px;
}