.container{
  width: 100%;
  height: 100%;
}
.links{
  display: inline;
}
.links ul{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr ;
  list-style-type: none;
}
.links li{
  display: inline-block;
  padding: 10px;
  margin: 3px;
  background-color: #01a998;
  border-radius: 15%;
  cursor: pointer;
}
.links li a{
  color: aliceblue;
  text-decoration: none;
  font-size: larger;
}