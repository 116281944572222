
.bills_cover h1{
  text-align: center;
  color: white;
}
.navbar {
  display: block;
}

.navbar .nav_cover {
  display: grid;
  grid-template-columns: 1fr 5fr 2fr;
}

.navbar .logo {
  color: #01a998;
  margin: auto;
  font-weight: bold;
}

.navbar .links {
  display: flex;
}
.navbar .icon {
  width: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  position: absolute;
  left: 83%;
  top: 18px;
  display: none;
  cursor: pointer;

}
.navbar .icon:hover span:nth-child(2) {
  width: 100%;
  transition: .5s;
}




.navbar .icon span {
  background-color: #333;
  margin-bottom: 5px;
  height: 2px;
}

.navbar .icon span:first-child {
  width: 100%;
}

.navbar .icon span:nth-child(2) {
  width: 60%;
}

.navbar .icon span:nth-child(3) {
  width: 100%;
}




.navbar li {
  list-style: none;
  margin: 0 12px 0 0;
}

.navbar a {

  display: block;
padding: 5px;
text-decoration: none;
color: #333;
transition: .5s;
}

.navbar a:hover {
  color: #01a998;
}

.navbar .login {
  display: flex;
}

.navbar .login_submit {
  width: 110px;
  height: 40px;
  margin: 10px 3px auto;
  color: #fff;
  background-color: #01a998;
  border-radius: 17px;
  cursor: pointer;
  border: 1px solid #0000001a;
}

.navbar .login_submit[type=submit]:focus {
  border: 0;
}

.navbar .login_submit:nth-child(1) {
  background-color: #fff;
  color: black;
}

.navbar .login_submit:nth-child(1):hover {
  color: #fff;
  background-color: #01a998;
}
@media(max-width:920px) {
  .navbar .links{
    display: none;
    transition: 4s;
  }
  .navbar .newclass {
    display: block;
    position: absolute;
    left: 58%;
    top: 30px;
    background: transparent;
    
  }
  .navbar .icon{
    display: inline-flex;
  }
  .navbar .icon:hover .links {
    display: block;
    }
    .navbar li{
      width: 180px;
      margin-top: 2px;
      background-color: #01a998;
      height: 30px;
      border-radius: 13px;
      text-align: center;
    }
    .navbar li:hover{
      margin-top: 5px;
      height: 34px;
      transition: 1s;
    }
    .navbar a {
      color:#fff;
      line-height:15px;
    }
    .navbar a:hover {
      color: #ffffff;
    }
}
@media(max-width:535px) {

  .navbar .nav_cover {
    display: block;
  }
  .navbar .newclass{
    left: 48%;
  }
  .navbar   li{
    width: 115px;
  }
  .navbar  .icon{
     left: 76%;
  }
  .navbar  .login {
    display: block;
    width: 20px;
    margin: 4px 0 0 0;
  }
  .navbar  .logo {
    color: green;
    margin: 19px 0 0 13px;
    font-weight: bold;
  }
  
}